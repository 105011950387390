import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Row, Col } from 'react-bootstrap'

export default ({ data }) => {
  const post = data.markdownRemark
  const bannerConfig = {
    title: post.frontmatter.title,
    imgUrl:
      post.frontmatter.featuredImage !== null
        ? post.frontmatter.featuredImage.childImageSharp.fluid.src
        : post.frontmatter.cover_image,
    coverColor: post.frontmatter.cover_color,
  }

  return (
    <Layout bannerConfig={bannerConfig}>
      <Row>
        <Col sm={8}>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </Col>
      </Row>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        cover_image
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cover_color
      }
    }
  }
`
